import { useEffect, useRef, useState } from 'react'
import {
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
} from '@chakra-ui/react'
import DayPicker, { DateUtils } from 'react-day-picker'
import FocusLock from 'react-focus-lock'
import 'react-day-picker/lib/style.css'
import './style.scss'
import { FIRST_DAY_OF_WEEK, LABELS, locale, MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from './config'
import MaskedInput from 'react-text-mask'
import { getDateReadFormat, getDateToPicker, isValidDate } from './dateFormatting'
import { FormLabel } from '../FormLabel'
import { CalendarIcon, SmallCloseIcon } from '@chakra-ui/icons'
import moment from 'moment'

const RANGE_SEPARATOR = '-'

export const InputDate = ({
  name,
  onChange,
  placement = 'top',
  showCurrentDate,
  hideClearButton,
  defaultValue,
  value,
  label,
  dayPickerProps = {},
  isRangePick,
  ...props
}) => {
  const { ...dayPickerPropsRest } = dayPickerProps

  const initialFocusRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)
  const [selectedDay, setSelectedDay] = useState(defaultValue ? defaultValue : value)
  const [inputState, setInputState] = useState(defaultValue ? defaultValue : value)

  const [selectedRange, setSelectedRange] = useState({
    from: undefined,
    to: undefined,
  })

  const handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return
    }

    if (isRangePick) {
      const range = DateUtils.addDayToRange(day, selectedRange)
      const fromStr = range.from ? getDateReadFormat(range.from) : ''
      const toStr = range.to ? getDateReadFormat(range.to) : ''
      const inputStateNew = toStr ? [fromStr, toStr].join(RANGE_SEPARATOR) : fromStr

      setSelectedRange(range)
      setInputState(inputStateNew)
    } else if (!selected) {
      console.log({ day })

      const dateReadFormat = getDateReadFormat(day)
      setSelectedDay(day)
      setInputState(dateReadFormat)
    }
  }

  const handleInputBlur = (e) => {
    const value = e.target.value
    console.log(isValidDate(value))

    if (isRangePick) {
      const range = value.split(RANGE_SEPARATOR)
      const from = range[0] ? getDateToPicker(range[0]) : undefined
      const to = range[1] ? getDateToPicker(range[1]) : undefined

      setSelectedRange({ from, to })
    } else if (isValidDate(value)) {
      setSelectedDay(getDateToPicker(value))
    } else {
      setSelectedDay('')
      setInputState('')
    }
  }

  const clear = () => {
    setSelectedRange({
      from: undefined,
      to: undefined,
    })
    setSelectedDay(undefined)
    setInputState(undefined)
  }

  useEffect(() => {
    if (onChange) {
      if (isRangePick) {
        onChange(selectedRange)
      } else if (selectedDay !== '') {
        const readDate = selectedDay && getDateReadFormat(selectedDay)
        onChange(readDate)
      }
    }
  }, [selectedDay, selectedRange])

  useEffect(() => {
    if (isRangePick) {
      if (value) {
        const fromStr = value.from ? getDateReadFormat(value.from) : ''
        const toStr = value.to ? getDateReadFormat(value.to) : ''
        const inputStateNew = toStr ? [fromStr, toStr].join(RANGE_SEPARATOR) : fromStr

        setSelectedRange(value)
        setInputState(inputStateNew)
      }
    } else {
      const day = value && moment(value).isValid() ? getDateToPicker(value) : ''

      if (day !== selectedDay) {
        setSelectedDay(day)
        setInputState(value)
      }
    }
  }, [value])

  const inputMask = [
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ]
  const rangeInputMask = [...inputMask, RANGE_SEPARATOR, ...inputMask]

  const hasSelected = inputState
  const selectedDays = isRangePick ? [selectedRange.from, selectedRange] : selectedDay

  const rangeDayPickerProps = {
    modifiers: { start: selectedRange.from, end: selectedRange.to },
  }

  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <MaskedInput
          mask={isRangePick ? rangeInputMask : inputMask}
          id={name}
          value={inputState}
          render={(ref, props) => <Input id={name} ref={ref} {...props} />}
          onChange={(e) => setInputState(e.target.value)}
          onBlur={handleInputBlur}
          autoComplete="off"
          {...props}
        />

        <Popover
          initialFocusRef={initialFocusRef}
          placement={placement}
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={close}
        >
          <PopoverTrigger>
            <InputRightElement height="100%" padding="0 10px">
              {hasSelected && !hideClearButton && (
                <Icon
                  as={SmallCloseIcon}
                  color="gray.600"
                  mr={2}
                  onClick={clear}
                  cursor="pointer"
                  transition="0.2s"
                  _hover={{
                    color: 'gray.500',
                  }}
                />
              )}
              <Icon
                as={CalendarIcon}
                color="gray.200"
                transition="0.2s"
                cursor="pointer"
                mr={1}
                onClick={open}
                _hover={{
                  color: 'gray.300',
                }}
              />
            </InputRightElement>
          </PopoverTrigger>
          <PopoverContent width="300px" zIndex={4} padding="8px 8px 6px 8px">
            <PopoverArrow />
            <PopoverBody>
              <FocusLock returnFocus persistentFocus={false}>
                <DayPicker
                  className={useColorModeValue('UIDatePickerLight', 'UIDatePickerDark')}
                  locale={locale}
                  months={MONTHS[locale]}
                  weekdaysLong={WEEKDAYS_LONG[locale]}
                  weekdaysShort={WEEKDAYS_SHORT[locale]}
                  firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
                  labels={LABELS[locale]}
                  numberOfMonths={1}
                  onDayClick={handleDayClick}
                  selectedDays={selectedDays}
                  {...(isRangePick && rangeDayPickerProps)}
                  {...dayPickerPropsRest}
                  ref={initialFocusRef}
                />
              </FocusLock>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </InputGroup>
    </FormControl>
  )
}
